<template>
  <div :class="{ rtl: isLtr }" class="p-3">
    <BackFr v-if="isLtr" />
    <VerticalMenu class="p-3 z-50">
      {{ $t('indicator') }}
    </VerticalMenu>
    <div class="report-menu-wrapper relative m-3 ml-40">
      <div class="flex justify-center items-stretch" style="height: 500px">
        <!-- right -->
        <div class="-ml-10 relative flex flex-col items-end">
          <div class="h-20 mb-8 relative -left-12">
            <Eye />
          </div>
          <div
            @click="changRoute('SlideHeart')"
            class="h-20 mb-8 cursor-pointer"
          >
            <Heart />
          </div>
          <div class="h-20 mb-8 z-30 relative -left-12"><Urin /></div>
        </div>
        <!-- center -->
        <div class="z-20">
          <img
            style="min-width: 360px; min-height: 450px"
            src="../assets/images/theme/full-body.png"
          />
          <div class="flex items-center justify-between -mt-20">
            <div
              @click="changRoute('Location')"
              class="mr-1 mb-1 -mt-9 cursor-pointer"
            >
              <Location />
            </div>
            <div
              @click="changRoute('PatientEnvironment')"
              class="h-20 mr-1 cursor-pointer"
            >
              <div :class="{ '-mr-4': !isLtr, '-mr-2': isLtr }" class="z-10">
                <div
                  :class="{ 'w-16 h-16': !isLtr, 'w-24 h-24': isLtr }"
                  class="circle-menu border-4 border-rose bg-white rounded-full p-1"
                >
                  <div
                    class="text-xs break-words border border-black rounded-full h-full w-full flex items-center justify-center text-center pt-1"
                  >
                    {{ $t('environment') }}
                  </div>
                </div>
              </div>
            </div>
            <div @click="changRoute('Medicines')" class="-mt-8 cursor-pointer">
              <Drugs />
            </div>
          </div>
        </div>
        <!-- left -->
        <div class="-mr-12 relative flex flex-col items-start">
          <div
            @click="changRoute('SlideTeeth')"
            class="h-20 mb-8 relative -right-12 cursor-pointer"
          >
            <Teeth />
          </div>
          <div class="h-20 mb-8"><Lungs /></div>
          <div
            @click="changRoute('Laboratory2')"
            class="h-20 z-30 mb-8 relative -right-6 cursor-pointer"
          >
            <Blood />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackFr from '../components/back/BackFr.vue'
import VerticalMenu from '@/components/information/VerticalMenu.vue'
import Eye from '@/components/information/items/Eye'
import Heart from '@/components/information/items/Heart'
import Urin from '@/components/information/items/Urin'
import Teeth from '@/components/information/items/Teeth'
import Lungs from '@/components/information/items/Lungs'
import Blood from '@/components/information/items/Blood'
import Location from '@/components/information/items/Location'
import Drugs from '@/components/information/items/Drugs'

export default {
  name: 'Information',
  components: {
    BackFr,
    Eye,
    Heart,
    Urin,
    Teeth,
    Lungs,
    Blood,
    Location,
    Drugs,
    VerticalMenu,
  },
  data() {
    return {
      showVerticalMenu: false,
    }
  },
  methods: {
    changRoute(val) {
      this.$router.push({ name: val })
    },
  },
}
</script>
<style>
.bg-fullbody-img {
  background-image: url('../assets/images/theme/whole-bg.png');
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 100%;
  background-size: 400px;
}
.bg-teeth-section {
  background-image: url('../assets/images/theme/bg-purple-wave.png');
  background-repeat: no-repeat;
  background-size: 115px;
}
.bg-lungs-section {
  background-image: url('../assets/images/theme/bg-blue-wave.png');
  background-repeat: no-repeat;
  background-size: 115px;
}
.bg-blood-section {
  background-image: url('../assets/images/theme/bg-aqua-wave.png');
  background-repeat: no-repeat;
  background-size: 115px;
}
</style>
