<template>
  <div class="vertical-menu absolute top-0 left-0 overscroll-y-auto">
    <h3
      class="font-bold cursor-pointer text-purple-400 text-base text-center"
      @click="toggle"
    >
      <slot></slot>
      <i
        class="fas mr-4 ml-2 text-xl text-red-600"
        :class="show ? 'fa-caret-down' : 'fa-caret-left'"
      ></i>
    </h3>
    <div
      v-show="show"
      class="vertical-menu--content w-56 px-2 flex-col items-center overflow-y-auto overflow-x-hidden"
    >
      <VerticalMenuItem
        v-for="(item, i) in menu"
        :key="i"
        :item="item"
        :borderColorClass="item.borderClass"
        @click.prevent="goto(item.to)"
        class="mt-4 mx-2 w-full pr-3 py-5 cursor-pointer"
      />
    </div>
  </div>
</template>

<script>
import VerticalMenuItem from '@/components/information/VerticalMenuItem'
export default {
  name: 'VerticalMenu',
  components: {
    VerticalMenuItem,
  },
  data() {
    return {
      show: false,
    }
  },
  computed: {
    menu() {
      return [
        {
          title: this.$t('heart'),
          date: this.convertDate(null, 'YYYY-M-D'),
          time: this.convertDate(null, 'HH:mm'),
          status: '72 pdm',
          borderClass: 'border-blue-400',
        },
        {
          title: this.$t('blood_pressure'),
          date: this.convertDate(null, 'YYYY-M-D'),
          time: this.convertDate(null, 'HH:mm'),
          status: '140/80 mmHg',
          borderClass: 'border-rose',
          to: { name: 'BloodPressure' },
        },
        {
          title: this.$t('blood_oxygen'),
          date: this.convertDate(null, 'YYYY-M-D'),
          time: this.convertDate(null, 'HH:mm'),
          status: '98%',
          borderClass: 'border-purple-400',
        },
        {
          title: this.$t('water_consumption'),
          date: this.convertDate(null, 'YYYY-M-D'),
          time: this.convertDate(null, 'HH:mm'),
          status: this.$t('normal'),
          borderClass: 'border-green-600',
        },
        {
          title: this.$t('diors'),
          date: this.convertDate(null, 'YYYY-M-D'),
          time: this.convertDate(null, 'HH:mm'),
          status: this.$t('normal'),
          borderClass: 'border-blue-400',
        },
        {
          title: this.$t('heat'),
          date: this.convertDate(null, 'YYYY-M-D'),
          time: this.convertDate(null, 'HH:mm'),
          status: '37',
          borderClass: 'border-blue-300',
        },
        {
          title: this.$t('daily_mobility'),
          date: this.convertDate(null, 'YYYY-M-D'),
          time: this.convertDate(null, 'HH:mm'),
          status: this.$t('normal'),
          borderClass: 'border-rose',
        },
        {
          title: this.$t('IMC'),
          date: this.convertDate(null, 'YYYY-M-D'),
          time: this.convertDate(null, 'HH:mm'),
          status: '19',
          borderClass: 'border-rose',
        },
        {
          title: this.$t('food'),
          date: this.convertDate(null, 'YYYY-M-D'),
          time: this.convertDate(null, 'HH:mm'),
          status: this.$t('normal'),
          borderClass: 'border-blue-500',
        },
        {
          title: this.$t('feces'),
          date: this.convertDate(null, 'YYYY-M-D'),
          time: this.convertDate(null, 'HH:mm'),
          status: this.$t('normal'),
          borderClass: 'border-blue-300',
        },
      ]
    },
  },
  methods: {
    goto(to) {
      !!to && this.$router.push(to)
    },
    toggle() {
      this.show = !this.show
    },
  },
}
</script>

<style lang="postcss" scoped>
.vertical-menu--content {
  min-height: theme('spacing.56');
  max-height: theme('spacing.96');
  transition: 0.3s;
}
</style>
