<template>
  <div class="bg-gray-200 pt-1 px-3 text-center rounded-lg">
    <img
      class="mx-auto -mt-10 h-24"
      src="@/assets/images/theme/drugs.png"
      alt=""
    />
    <h3 :class="{ 'py-4': isLtr }" class="text-rose">
      {{ $t('drugs') }}
    </h3>
    <h3 v-if="!isLtr">Drugs</h3>
  </div>
</template>

<script>
export default {
  name: 'Drugs',
}
</script>

<style></style>
