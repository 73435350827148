<template>
  <div
    @click="$router.go(-1)"
    class="cursor-pointer group hover:bg-rose hover:text-white duration-200 border-2 p-2 text-xs inline-block transform bg-white -rotate-90 mt-5"
  >
    <i
      class="duration-200 group-hover:text-white far fa-arrow-circle-right text-red-500"
    ></i>
    RETOUR
  </div>
</template>

<script>
export default {
  name: 'BackFr',
}
</script>

<style></style>
app.config.errorHandler = (err, vm, info) => { // handle error }
