<template>
  <div
    class="hover:bg-blue-200 flex justify-between items-center bg-gray-200 rounded-full font-bold h-6 shadow-md"
  >
    <span
      :class="isLtr ? 'text-xs' : 'text-base'"
      class="leading-5 break-words font-bold w-16"
      >{{ item.title }}</span
    >
    <span class="text-xs mx-auto">
      <span class="block opacity-50">{{ $t('time_title') }}</span>
      <span class="opacity-70 text-xs mt-px inline-block">{{ item.date }}</span>
      <span class="opacity-70 text-xs mt-px">{{ item.time }}</span>
    </span>
    <CircleItem class="flex-shrink-0 w-12 h-12" :class="borderColorClass">{{
      item.status
    }}</CircleItem>
  </div>
</template>

<script>
import CircleItem from '@/components/information/CircleItem'
export default {
  name: 'VerticalMenuItem',
  components: { CircleItem },
  props: {
    item: {
      type: Object,
      required: true,
    },
    borderColorClass: {
      type: String,
      default: 'border-gray-500',
    },
  },
}
</script>
