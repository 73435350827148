<template>
  <div class="flex items-center h-full">
    <div class="order-1 bg-eyes-section w-44 mx-1 h-full relative z-0">
      <BaseBadge color="white" class="p-1 absolute -top-3 right-16">
        <img class="h-8 w-auto" src="@/assets/images/theme/eye.png" />
      </BaseBadge>
      <div
        class="h-16 text-xs text-white pt-5 pl-4 flex items-stretch justify-center text-center"
      >
        <div class="">
          <span class="block border-b border-white">{{ $t('right_eye') }}</span>

          <span class="block ltr py-1 border-b border-white border-dotted">
            -2 (90,0.75)
          </span>
          <span class="block mt-1">ADD 3</span>
        </div>
        <div class="mx-3 self-stretch text-center">
          <div class="block w-px bg-white h-full"></div>
        </div>
        <div class="">
          <span class="block border-b border-white">{{ $t('left_eye') }}</span>

          <span class="block ltr py-1 border-b border-white border-dotted">
            -2 (90,0.75)
          </span>
          <span class="block mt-1">ADD 3</span>
        </div>
      </div>
    </div>
    <CircleMenu class="order-2 -mr-4 z-10" :text="$t('report')" />
  </div>
</template>

<script>
import CircleMenu from '@/components/information/CircleMenu.vue'
export default {
  name: 'Eye',
  components: { CircleMenu },
}
</script>

<style lang="postcss">
.bg-eyes-section {
  @apply bg-no-repeat bg-contain bg-center;
  background-image: url('../../../assets/images/theme/bg-purple-wave-wide.png');
}
</style>
