<template>
  <div
    :class="{ 'px-3': isLtr }"
    class="bg-gray-200 p-1 text-center rounded-lg"
  >
    <img class="mx-auto -mt-5" src="@/assets/images/theme/locale.png" alt="" />
    <h3 :class="{ 'py-4': isLtr }" class="text-rose">
      {{ $t('location') }}
    </h3>
    <h3 v-if="!isLtr">location</h3>
  </div>
</template>

<script>
export default {
  name: 'Location',
}
</script>

<style></style>
