<template>
  <div class="flex items-center h-full">
    <CircleMenu class="order-1 -ml-4 z-10" :text="$t('report')" />
    <div class="order-2 bg-teeth-section w-28 mx-1 h-full relative z-0">
      <BaseBadge color="white" class="p-1 absolute -top-3 left-8">
        <img class="h-8 w-auto" src="@/assets/images/theme/teeth.png" />
      </BaseBadge>
      <div class="text-2xl mt-8 text-white text-center">
        {{ $t('teeth') }}
      </div>
    </div>
  </div>
</template>

<script>
import CircleMenu from '@/components/information/CircleMenu.vue'
export default {
  name: 'Teeth',
  components: { CircleMenu },
}
</script>

<style lang="postcss">
.bg-teeth-section {
  @apply bg-no-repeat bg-contain bg-center;
  background-image: url('../../../assets/images/theme/bg-purple-wave.png');
}
</style>
