<template>
  <div
    class="bg-gray-200 rounded-full p-3 flex items-center justify-center"
    style="border-width: 6px"
  >
    <h3 class="block mt-1 break-words text-xs text-center font-semibold">
      <slot></slot>
    </h3>
  </div>
</template>

<script>
export default {
  name: 'CircleItem',
}
</script>

<style></style>
