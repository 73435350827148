<template>
  <div
    class="circle-menu border-4 border-rose bg-white rounded-full w-16 h-16 p-1"
  >
    <div
      class="text-xs break-words border border-black rounded-full h-full w-full flex items-center justify-center text-center pt-1"
    >
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'CircleMenu',
  props: {
    text: {
      type: String,
      rquired: true,
    },
  },
}
</script>

<style></style>
